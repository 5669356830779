import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";

import config from "../../../config";
import { useDispatch, useSelector } from "react-redux";
import TitleBreadCrumb from "../../../components/TitleBreadCrumb";
import FilterAndAssignLeads from "../../GetAllLeads/FilterAndAssignLeads";
import DataTable from "react-data-table-component";
import EditLeadInformation from "../../GetAllLeads/EditLeadInformation";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { customStyles } from "../../../utils/TableCssComponent";
import { Link } from "react-router-dom";
import FilterComponent from "../ManualData/FilterComponent";
import ManageAccount from "./ManageAccount";

const GetFacebookLead = () => {
    const [campignId, setCampignId] = useState("");
    const [finalData, setFinalData] = useState("");
    const initialValues = {
        campignLeadId: JSON.parse(localStorage.getItem('campaign')), // Set your initial value for leadId here
    };
    const { loading, userInfo, error } = useSelector((store) => store.userInfo) || " ";
    const userPermission = userInfo && userInfo.payload && userInfo.payload && userInfo.payload.role[0]?.permission;
    const expectedLead = ['read', 'create'];
    const expectedManager = ['read', 'create', 'update'];
    const expectedAdmin = ["read", "create", "update", 'delete'];
    // sort the data for Admin
    const sortData = expectedManager.slice().sort();
    const sortExpectedLead = expectedLead.slice().sort();
    const sortExpectedAdmin = expectedAdmin.slice().sort()
    const sortedUserPermissions = userPermission.map(permission => permission.value).sort();
    const compairedAdmin = JSON.stringify(sortedUserPermissions) === JSON.stringify(sortExpectedAdmin);
    const compairedData = JSON.stringify(sortedUserPermissions) === JSON.stringify(sortData);
    const compaireLead = JSON.stringify(sortedUserPermissions) == JSON.stringify(sortExpectedLead)
    const validationSchema = Yup.object({
        campignLeadId: Yup.string().required("Lead Id is required"),
    });
    var token = localStorage.getItem("token");
    const adSets = useSelector((store) => store.compaignReducer?.userInfo?.data)
    const currentUser = userInfo && userInfo.payload && userInfo.payload._id

    // const finalData  = useSelector((store)=>store.facebookLeadReducer?.userInfo?.data)



    //try

    const [dataItem, setDataItem] = useState('')
    const [filterText, setFilterText] = useState(" ");
    const [FinalResponse, setFinalResponse] = useState([]);
    const [saveResponse, setSaveResponse] = useState([]);
    const [selectedLeads, SetselectedLeads] = useState([])
    const [rowData, setrowData] = useState([]);
    const onSubmit = async (values) => {
        const leadsData = JSON.parse(values.campignLeadId);
        localStorage.setItem('pageName', leadsData.name);
        localStorage.setItem('id', leadsData.id);

        //for Direct Facebook leads and save in DB 
        const fetchDatafromFacebook = async () => {

            try {
                const response = await axios.post(
                    `${config.API_URL}/leadSource/save/lead/bypage`, {
                    name: leadsData.name,
                    id: leadsData.id
                },

                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                            // Add any other headers you need
                        },
                    }
                );
                setSaveResponse(response)
            } catch (error) {
                console.error("Error fetching ads:", error.message);
            }
        };
        fetchDatafromFacebook(); // Call the async function here
        //Fetch the Data from DB
        const fetchData = async () => {
            const defaultPageName = leadsData && leadsData.name;
            const defaultId = leadsData || leadsData.id;
            try {
                const response = await axios.post(
                    `${config.API_URL}/leadSource/fetch/lead/bypage`, {
                    pageName: localStorage.getItem('pageName') || defaultPageName,
                    id: localStorage.getItem('id') || defaultId
                    // pageName: leadsData.name,
                    // id: leadsData.id
                },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                            // Add any other headers you need
                        },
                    }
                );

                setFinalResponse(response.data.data);

            } catch (error) {
                console.error("Error fetching ads:", error.message);
            }
        };

        fetchData(); // Call the async function here
    };
    const dispatch = useDispatch()
    // load the Pages from Facebook
    //Fetch the Data from DB
    useEffect(() => {
        const fetchData = async () => {

            try {
                const response = await axios.post(
                    `${config.API_URL}/leadSource/fetch/lead/bypage`, {
                    pageName: localStorage.getItem('pageName'),
                    id: localStorage.getItem('id')
                    // pageName: leadsData.name,
                    // id: leadsData.id
                },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                            // Add any other headers you need
                        },
                    }
                );

                setFinalResponse(response.data.data);

            } catch (error) {
                console.error("Error fetching ads:", error.message);
            }
        };

        fetchData(); //
    }, [])

    // Fetch the Data From Facebook Page:
    useEffect(() => {
        const fetchData = async () => {

            try {
                const response = await axios.get(
                    `${config.API_URL}/leadSource/fetch/fb/page`,

                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                            // Add any other headers you need
                        },
                    }
                );

                setFinalData(response.data.data);

            } catch (error) {
                console.error("Error fetching ads:", error.message);
            }
        };

        fetchData(); // Call the async function here
    }, [dataItem]);

    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
        false
    );
    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };
        return (
            <FilterComponent
                onFilter={e => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);
    const ExpandableComponent = ({ data }) => {
        return (
            <div>
                <>

                    <ul className="p-4 bg-light">
                        <p style={{ fontSize: "13px" }}> <strong>Email:</strong> {data && data.email}</p>
                        <p style={{ fontSize: "13px" }}> <strong>Phone:</strong> {data && data.phone}</p>
                        <p style={{ fontSize: "13px" }}><strong>Description : </strong>{data && data.description}</p>
                        <p style={{ fontSize: "13px" }}><strong>Services Enquired : </strong>{data && data.servicesEnquired}</p>
                    </ul>
                </>
            </div>
        );
    };
    const filteredItems = FinalResponse && FinalResponse.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );
    const columns = useMemo(() =>
        [
            {
                name: "Full Name",
                selector: (row) => <Link to={`/leads/${row?._id}`} state={row} tabindex="0" data-toggle="popover" data-trigger="focus" title={`${row?.firstName} ${row?.lastName}`}>{row?.firstName} {row?.lastName}</Link>,
            },
            {
                name: "Email",
                selector: (row) => <Link to={`/leads/${row?._id}`} state={row} tabindex="0" data-toggle="popover" data-trigger="focus" title={`${row?.email}`}>{row?.email}</Link>,
            },

            {
                name: "Phone Number",
                selector: (row) => <Link to={`/leads/${row?._id}`} state={row} tabindex="0" data-toggle="popover" data-trigger="focus" title={`${row?.phone}`}>{row?.phone}</Link>,
            },
            //   {
            //       name: "Users Status",
            //       selector: (row) =>
            //           <div>
            //               {row && row.users  && row.users.length == 0 ? <span className="badge badge-warning light border-0 fs-12"><i class="fa-solid fa-not-equal me-1 "></i>Not-Assigned</span> :
            //                   (row && row.users && row.users.length > 0 && row && row.users && row.users.every(item => !item.currentUser)) || (row && row.users && row.users.find(item => item.id._id == currentUser && item.currentUser == false)) ? (
            //                       <>
            //                           <p >
            //                               <span className="badge badge-danger light border-0 fs-12"><i class="fa-solid fa-not-equal me-1"></i>Not-Attended</span>
            //                               {/* <strong>Not-attended</strong> */}
            //                           </p>
            //                       </>
            //                   )
            //                       : row && row.users && row.users.length > 0 ? row && row.users && row.users.filter((item, index) => (item && item.id._id == currentUser || compairedAdmin) && item.currentUser).map((ele) => {
            //                           return (
            //                               <>
            //                                   {ele && ele.leadStatus == "REJECTED" ? <p><span className="badge badge-danger light border-0 fs-12"><i class="fa-solid fa-not-equal me-1 fs-12"></i>Not-Attended</span> </p> : ele.leadStatus == "ACCEPTED" ?
            //                                   <span className="badge badge-success light border-0 fs-12"><i class="fa-solid fa-not-equal me-1"></i>Attended</span> : <p style={{
            //                                           background: "orange",
            //                                           fontSize: "12px",
            //                                           color: "white",
            //                                           padding: "6px",
            //                                           borderRadius: "4px",
            //                                           // borderBottom:"2px solid #071739",
            //                                           marginBottom: "0px"
            //                                       }}><strong>Pending</strong></p>}
            //                               </>
            //                           )
            //                       }) : <p style={{
            //                           background: "rgb(219 255 98 / 62%)",
            //                           color: "#431010",
            //                         //   padding: "6px 8px",
            //                           borderRadius: "0px",
            //                           borderBottom: "2px solid #071739",

            //                       }}><strong>Not-assigned</strong></p>}

            //           </div>
            //   },
            {
                name: 'User Status',
                cell: row =>
                    <div>
                        {row && row.users && row.users.length > 0 ?
                            //  <div className="d-flex">
                            <div className="dropdown absolute">
                                <button type="button" className="btn btn-success light sharp" data-bs-toggle="dropdown" aria-expanded="false">
                                    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                            <rect x={0} y={0} width={24} height={24} />
                                            <circle fill="#000000" cx={5} cy={12} r={2} />
                                            <circle fill="#000000" cx={12} cy={12} r={2} />
                                            <circle fill="#000000" cx={19} cy={12} r={2} />
                                        </g>
                                    </svg>
                                </button>
                                <div className="dropdown-menu" style={{}}>
                                    <div id="DZ_W_TimeLine" className="widget-timeline dz-scroll height100 my-4 px-2">
                                        <ul className="timeline">
                                            {row && row.users && row.users.length == 0 ?
                                                <span className="badge badge-warning light border-0"><i class="fa-solid fa-not-equal me-1"></i>Not-Assigned</span> :
                                                compaireLead
                                                    ? row &&
                                                    row.users &&
                                                    row.users.length > 0 &&
                                                    row.users
                                                        .filter((ele, index) => ele?.id?._id === currentUser)
                                                        .map((item, index) => (
                                                            <li key={index}>
                                                                {item.leadStatus === "ACCEPTED" ? (
                                                                    <>
                                                                        <div className="timeline-badge success"></div>
                                                                        <div className="timeline-panel p-2">
                                                                            <h6 className="mb-0 f-11">{item && item.leadStatus}</h6>
                                                                            <strong className="text-primary f-12">{item && item.id && item.id.firstName}</strong>
                                                                            <h6 className="mb-0 fs-11">{item && item.assignedDate}</h6>
                                                                        </div>
                                                                    </>
                                                                ) : item.leadStatus === "PENDING" ? (
                                                                    <>
                                                                        <div className="timeline-badge warning"></div>
                                                                        <div className="timeline-panel p-2">
                                                                            <h6 className="mb-0 f-11">{item && item.leadStatus}</h6>
                                                                            <strong className="text-primary f-12">{item && item.id && item.id.firstName}</strong>
                                                                            <h6 className="mb-0 fs-11">{item && item.assignedDate}</h6>
                                                                        </div>
                                                                    </>
                                                                )
                                                                    : item.leadStatus === "SKIPPED" ? (
                                                                        <>
                                                                            <div className="timeline-badge danger"></div>
                                                                            <div className="timeline-panel p-2">
                                                                                <h6 className="mb-0 f-11">{item && item.leadStatus}</h6>
                                                                                <strong className="text-primary f-12">{item && item.id && item.id.firstName}</strong>
                                                                                <h6 className="mb-0 fs-11">{item && item.assignedDate}</h6>
                                                                            </div>
                                                                        </>
                                                                    )
                                                                        : item.leadStatus === "REJECTED" ? (
                                                                            <>
                                                                                <div className="timeline-badge danger"></div>
                                                                                <div className="timeline-panel p-2">
                                                                                    <h6 className="mb-0 f-11">{item && item.leadStatus}</h6>
                                                                                    <strong className="text-primary f-12">{item && item.id && item.id.firstName}</strong>
                                                                                    <h6 className="mb-0 fs-11">{item && item.assignedDate}</h6>
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            <span className="badge badge-warning light border-0"><i class="fa-solid fa-not-equal me-1"></i>Not-Assigned</span>
                                                                        )}
                                                            </li>
                                                        ))
                                                    : (compairedAdmin || compairedData) &&
                                                    row &&
                                                    row.users &&
                                                    row.users.length > 0 &&
                                                    row.users.map((item, index) => {
                                                        return (
                                                            <li key={index}>
                                                                {item.leadStatus === "ACCEPTED" ? (
                                                                    <>
                                                                        <div className="timeline-badge success"></div>
                                                                        <div className="timeline-panel p-2">
                                                                            <h6 className="mb-0 f-11">{item && item.leadStatus}</h6>
                                                                            <strong className="text-primary f-12">{item && item.id && item.id.firstName}</strong>
                                                                            <h6 className="mb-0 fs-11">{item && item.assignedDate}</h6>
                                                                        </div>
                                                                    </>
                                                                ) : item.leadStatus === "PENDING" ? (
                                                                    <>
                                                                        <div className="timeline-badge warning"></div>
                                                                        <div className="timeline-panel p-2">
                                                                            <h6 className="mb-0 f-11">{item && item.leadStatus}</h6>
                                                                            <strong className="text-primary f-12">{item && item.id && item.id.firstName}</strong>
                                                                            <h6 className="mb-0 fs-11">{item && item.assignedDate}</h6>
                                                                        </div>
                                                                    </>
                                                                ) : item.leadStatus === "SKIPPED" ? (
                                                                    <>
                                                                        <div className="timeline-badge warning"></div>
                                                                        <div className="timeline-panel p-2">
                                                                            <h6 className="mb-0 f-11">{item && item.leadStatus}</h6>
                                                                            <strong className="text-primary f-12">{item && item.id && item.id.firstName}</strong>
                                                                            <h6 className="mb-0 fs-11">{item && item.assignedDate}</h6>
                                                                        </div>
                                                                    </>
                                                                )
                                                                    : item.leadStatus === "REJECTED" ? (
                                                                        <>
                                                                            <div className="timeline-badge danger"></div>
                                                                            <div className="timeline-panel p-2">
                                                                                <h6 className="mb-0 fs-11">{item && item.leadStatus}</h6>
                                                                                <strong className="text-primary fs-12">{item && item.id && item.id.firstName}</strong>
                                                                                <h6 className="mb-0 fs-11">{item && item.assignedDate}</h6>
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <div className="timeline-badge danger"></div>
                                                                            <div className="timeline-panel p-2">
                                                                                <p>Not-Assign</p>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                            </li>
                                                        )

                                                    })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            //  </div>
                            :
                            <span className="badge badge-warning light border-0"><i class="fa-solid fa-not-equal me-1"></i>Not-Assigned</span>}
                    </div>



            },
            {
                name: "Your Budget",
                selector: (row) => <Link to={`/source/custom/${row?._id}`} state={row} tabindex="0" data-toggle="popover" data-trigger="focus" title={row?.budget}>{row?.budget}</Link>,
            },
            {
                name: "Created Date",
                selector: (row) => <Link to={`/source/custom/${row?._id}`} state={row} tabindex="0" data-toggle="popover" data-trigger="focus" title={row?.budget}>{row?.createdDate}</Link>,
            },
            {
                name: "Created Time",
                selector: (row) => <Link to={`/source/custom/${row?._id}`} state={row} tabindex="0" data-toggle="popover" data-trigger="focus" title={row?.budget}>{row?.createdTime
                }</Link>,
            },
            {
                name: "Lead Source",
                selector: (row) => <Link to={`/source/custom/${row?._id}`} state={row} tabindex="0" data-toggle="popover" data-trigger="focus" title={`${row?.leadSource}`}>{row?.leadSource}</Link>,
            },
            {
                name: 'Action',
                selector: (row) =>

                    <div> <a
                        onClick={() => setrowData(row)}
                        data-bs-toggle="offcanvas"
                        href="#EditLead"
                        aria-controls="EditLead"
                        className="btn btn-primary shadow btn-xs sharp me-1"
                        title='Edit'
                    >
                        <i className="fa fa-pencil" />
                    </a>
                    </div>
            }
        ], []
    )
    const handleSelectedRowsChange = (state) => {
        SetselectedLeads(state && state.selectedRows)
    };

    return (
        <>

            <div className='page-titles'>
                <TitleBreadCrumb title="Facebook Leads" />
            </div>
            <div className="pt-5">
                <div className='container-fluid'>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card-header flex-wrap d-flex justify-content-end border-0">
                                {compairedAdmin ? <button type="button" class="btn btn-warning btn-sm me-1" data-bs-toggle="modal" data-bs-target="#manageaccount"><i class="fa-solid fa-upload me-2"></i>Manage Account</button> : ""}
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-xl-12 bst-seller">
                            <div className='card border-0'>
                                <div className='card-body'>

                                    <div className='row'>
                                        <div className='col-xl-4'>
                                            <form>
                                                <label>Search By Type</label>
                                                <div className="input-group border rounded-pill pdng">
                                                    <input type="search" placeholder="Search here.." aria-describedby="button-addon3" className="form-control bg-none border-0 ml-4" onChange={(e) => setFilterText(e.target.value)}
                                                        filterText={filterText} />
                                                    <div className="input-group-append border-0">
                                                        <button id="button-addon3" type="button" className="btn btn-link text-success"><i className="fa fa-search" /></button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>

                                        {compairedAdmin ? <div className='col-xl-4'>
                                            <Formik
                                                initialValues={initialValues}
                                                validationSchema={validationSchema}
                                                onSubmit={onSubmit}
                                            >
                                                {({ values, setFieldValue, errors, dirty, isValid }) => {

                                                    return (
                                                        <div className="col-lg-12">
                                                            <Form>
                                                                <label>Select Facebook Page</label>
                                                                <div className='d-flex'>
                                                                    <Field
                                                                        as="select"
                                                                        name="campignLeadId"
                                                                        className="default-select style-1 form-control asn-slt"

                                                                    >
                                                                        <option value="" data-display="Select">--SELECT PAGES--</option>
                                                                        {finalData &&
                                                                            finalData.map((ele) => (
                                                                                <option key={ele.id} value={JSON.stringify(ele)}>
                                                                                    {ele.name}
                                                                                </option>
                                                                            ))}
                                                                    </Field>
                                                                    <ErrorMessage
                                                                        name="campignLeadId"
                                                                        render={(msg) => (
                                                                            <small style={{ color: "red" }}>{msg}</small>
                                                                        )}
                                                                    />
                                                                    <button className="btn btn-info w-25 fs-14 rounded-pill ms-1" type='submit'>Submit</button>

                                                                </div>
                                                            </Form>
                                                        </div>
                                                    );
                                                }}
                                            </Formik>
                                        </div> : ""}


                                        <FilterAndAssignLeads
                                            filteredItems={FinalResponse} selectedData={selectedLeads} selectedLeads={selectedLeads} />



                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body p-0">
                        <EditLeadInformation initialData={rowData} />
                        <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting ">
                            {/* <FacebooksFilter/> */}
                            {
                                filteredItems === undefined
                                    ?
                                    <Skeleton count={5} />
                                    :

                                    <DataTable
                                        customStyles={customStyles}
                                        columns={columns}
                                        data={filteredItems}
                                        pagination
                                        selectableRowsHighlight
                                        highlightOnHover
                                        FixedHeader
                                        fixedHeaderScrollHeight='450px'
                                        expandableRows
                                        expandableRowsComponent={ExpandableComponent}
                                        subHeaderComponent={subHeaderComponent}
                                        selectableRows
                                        onSelectedRowsChange={handleSelectedRowsChange}
                                    />
                            }
                        </div>
                    </div>
                </div>
                {/* <FacebookTable finalData={FinalResponse} /> */}
            </div>
            {compairedAdmin ? <ManageAccount /> : ""}
        </>
    );
};

export default GetFacebookLead;
