import React from 'react'

function Page404() {
  return (
    <div>
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="error-page">
              <div className="error-inner text-center">
                <div className="dz-error" data-text={404}>404</div>
                <h4 className="error-head"><i className="fa fa-exclamation-triangle text-warning" /> You are Loged out, Please Login Again ! </h4>
                <div>
                  <a href="#/user/login" className="btn btn-secondary">Login Page</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Page404