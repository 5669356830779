
import {
    PROFILE_FAIL,
    PROFILE_REQUEST,
    PROFILE_SUCCESS,
   
  } from "../../constant/profille/profile";
  
  const token = localStorage.getItem("token") || "";
  const initialState = {
    loading: false,
  };
  
  const fetchProfileReducer = (state = initialState, action) => {
    switch (action.type) {
      case PROFILE_REQUEST:
        return { ...state, loading: true };
      case PROFILE_SUCCESS :
        return { ...state, loading: false, userInfo: action.payload };
      case PROFILE_FAIL:
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };
  export { fetchProfileReducer };
  