import React, { Children } from 'react'
import DefaultLayout from '../../layout/DefaultLayout'
import EditRole from '../../views/AdminTool/Role/EditRole'
import Analytics from '../../views/Analytics/Analytics'
import Products from '../../views/Products/Products'
import GetProductById from '../../views/Products/GetProductById'
import Facebook from '../../views/Source/Facebook/Facebook'
import GenerateLead from '../../views/Source/GoogleAdds/GoogleAdsForm/GenerateForm'
// import GenerateForm from '../../views/Source/GoogleAdds/GenerateForm'
import GoogleForm from '../../views/Source/GoogleAdds/GoogleAdsForm/index'
const Dashboard = React.lazy(() => import('../../views/Dashboard/index'))
const Role = React.lazy(() => import('../../views/AdminTool/Role/Roles'))
// const Plan = React.lazy(() => import('../../views/AdminTool/Plan'))
const Company = React.lazy(() => import('../../views/AdminTool/Company/Company'))
const User = React.lazy(() => import('../../views/AdminTool/Users/User'))
const AllLeads = React.lazy(()=>import ('../../views/GetAllLeads/AllLeads'))
const ViewDetails = React.lazy(()=>import ('../../views/GetAllLeads/Viewdetails/ViewDetails'))
const ManualData = React.lazy(()=>import("../../views/Source/ManualData/ManualData"))
const Profile = React.lazy(() => import('../../../src/views/UserProfile/index'));
const Report = React.lazy(()=> import('../../views/Reports/index') )
const GoogleAds = React.lazy(()=> import('../../views/Source/GoogleAdds/index') )
const Websites = React.lazy(()=> import('../../views/Source/Websites/index') )
const MainRoutes = {
        path: "/",
        element: <DefaultLayout />,
        children: [
                {
                        path: '/',
                        permissions: [{ label: "Read", value: "read" },],
                        element: <Dashboard />
                },
                {
                        path: '/reports',
                        permissions: [{ label: "Read", value: "read" }, { label: "Create", value: "create" }, { label: "Update", value: "update" }, { label: "Delete", value: "delete" }],
                        element: <Report />
                },
                {
                        path: '/company',
                        // permissions: [{ label: "Read", value: "read" }, { label: "Create", value: "create" }, { label: "Update", value: "update" }, { label: "Delete", value: "delete" }, { label: "Root", value: "root" }],
                        permissions: [{ label: "Read", value: "read" }],
                        element: <Company />
                },
                {
                        path: '/role',
                        // permissions: [{ label: "Read", value: "read" }, { label: "Create", value: "create" }, { label: "Update", value: "update" }, { label: "Delete", value: "delete" }],
                        permissions: [{ label: "Read", value: "read" }],
                        element: <Role />
                },
                {
                        path: '/role/update/:id',
                        // permissions: [{ label: "Read", value: "read" }, { label: "Create", value: "create" }, { label: "Update", value: "update" }, { label: "Delete", value: "delete" }],
                        permissions: [{ label: "Read", value: "read" }],
                        element: <EditRole/>
                },
                {
                        path: '/user',
                        // permissions: [{ label: "Read", value: "read" }, { label: "Create", value: "create" }, { label: "Update", value: "update" }, { label: "Delete", value: "delete" }],
                        permissions: [{ label: "Read", value: "read" }],
                        element: <User />
                },
                {
                        path: '/all-leads',
                        permissions: [{ label: "Read", value: "read" },],
                        element: <AllLeads />
                },
                {
                        path: '/leads/:id',
                        permissions: [{ label: "Read", value: "read" },],
                        element: <ViewDetails />
                },
                {
                        path: '/analytics',
                        permissions: [{ label: "Read", value: "read" },],
                        element: <Analytics />
                },
                {
                        path: '/products',
                        permissions: [{ label: "Read", value: "read" }],
                        element: <Products />
                },
                {
                        path: '/product/:id',
                        permissions: [{ label: "Read", value: "read" }],
                        element: < GetProductById />
                },

               
                {
                        path: '/manual-data',
                        permissions: [{ label: "Read", value: "read" }],
                        element: <ManualData />
                },

                  {

                        path: '/facebook',
                        permissions: [{ label: "Read", value: "read" }],
                        element: <Facebook />

                },
                //   {

                //         path: '/websites',
                //         permissions: [{ label: "Read", value: "read" }],
                //         element: <Websites />

                // },
                  {

                        path: '/google_Ads',
                        permissions: [{ label: "Read", value: "read" }],
                        element: <GoogleAds />

                },
                  {

                        path: '/generateform',
                        permissions: [{ label: "Read", value: "read" }],
                        element: <GoogleForm />

                },
                {
                        path: '/profile',
                        permissions: [{ label: "Read", value: "read" }],
                        element: < Profile />
                },
         
        ]
}

export default MainRoutes;

