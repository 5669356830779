import { useEffect, useState } from "react";

const IframeModal = ({ adData }) => {
    const iframe = `<iframe style="height: 241.5px; overflow:scroll; width: 100%" scrolling="no" src="https://googleads.decodesales.in?ad-name=${adData.adName}&ad-id=${adData.adId}&ad-key=${adData.company}&ad-redirection=""" data-ad-id="${adData.adId}" data-ad-name="${adData.adName}" data-ad-key="${adData.company}"></iframe>`
    const [status, setStatus] = useState("");
    function CopyClipboard(){
        navigator.clipboard.writeText(iframe);
        setStatus("Copied")
        setTimeout(() => {
            setStatus("");
        }, 3000);
    }
    return (
        <div className="modal fade" id="iframeModal" tabIndex={-1} aria-labelledby="iframeModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-center">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="iframeModalLabel">Add Iframe to your App</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                    </div>
                    <div className="modal-body" style={{ background: "#FAFAFA", color: "black"}}>
                        <div className='text-end mb-3'>
                            <span className="me-3 text-success">{status}</span>
                            <button
                                type="button"
                                className='btn btn-success btn-sm'
                                onClick={CopyClipboard}
                                >
                                <i className='fa fa-copy'></i>
                            </button>
                        </div>
                        {iframe}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IframeModal