import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import config from '../../config';
import Swal from "sweetalert2";
import { fetctAllLead } from '../../redux/action/allleads/getalllead';
import { fetchLeadSource } from '../../redux/action/LeadSource/LeadSource';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { fetchUserApi } from '../../redux/action/UserApi/UserApi';
function FilterAndAssignLeads(props) {
    const selectedLeads = props && props.selectedLeads
    const [selectedRowData, setSelectedRowData] = useState('')  //selected Rows Data
    var token = localStorage.getItem("token");
    useEffect(() => {
        setSelectedRowData(props.selectedData)
    }, [props.selectedData]);
    useEffect(() => {
        dispatch(fetchUserApi(token));
    }, [])
    const leadsdata = props && props.filteredItems;
    const selectedData = props && props.selectedData;
    const [filterText, setFilterText] = React.useState("");
    const expectedLead = ['read', 'create'];
    const expectedManager = ['read', 'create', 'update'];
    const expectedAdmin = ['read', 'create', 'update', 'delete'];
    const sortAdmin = expectedAdmin.slice().sort();
    const sortData = expectedManager.slice().sort();
    const sortExpectedLead = expectedLead.slice().sort();
    const { userApi } = useSelector((store) => store) || " ";
    const { userInfo } = useSelector((store) => store.userInfo) || " ";
    const users = userApi && userApi.userInfo && userApi.userInfo.data;
    const currentUser = userInfo && userInfo.payload && userInfo.payload._id;
    const userPermission = userInfo && userInfo.payload && userInfo.payload && userInfo.payload.role[0]?.permission;
    const sortedUserPermissions = userPermission.map(permission => permission.value).sort();
    const checkPermissionMatch = (permissions, expectedPermissions) => {
        const permissionValues = permissions && permissions.map((permission) => permission.value);
        return expectedPermissions.every((perm) => permissionValues && permissionValues.includes(perm)) && permissions.length === expectedPermissions.length;
    };
    const filteredItems = leadsdata && leadsdata.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );
    const accessLead = filteredItems && filteredItems.filter((ele) =>
        ele &&
        ele.users &&
        ele?.users?.some((item) => item.leadStatus === "ACCEPTED"));
    // Lead assignment by Admin
    const accessLeadByAdmin = filteredItems && filteredItems.filter((ele) =>
        ele?.users?.length === 0
    );
    const dispatch = useDispatch()
    const [ids, setIds] = useState('')
    const multiValues = selectedData && selectedData.map(obj => obj);
    const isEveryItemMatching = multiValues && multiValues.every((targetId) =>
        accessLead.some((item) => item._id && item._id.toString() === targetId)
    );
    const isEveryItemMatchingAsAdmin = multiValues && multiValues.every((targetId) =>
        accessLeadByAdmin.some((item) => item._id && item._id.toString() === targetId)
    );
    // Assign User List 
    const leadUsers = users && users.filter((user) =>
        checkPermissionMatch(user && user.role && user.role.permission, sortExpectedLead)
    );
    const managerUsers = users && users.filter((user) =>
        checkPermissionMatch(user && user.role && user.role.permission, sortData)
    );
    const adminUsers = users && users.filter((user) =>
        checkPermissionMatch(user && user.role && user.role.permission, sortAdmin)
    );
    const compairedAdmin = JSON.stringify(sortedUserPermissions) === JSON.stringify(sortAdmin);
    const compairedData = JSON.stringify(sortedUserPermissions) === JSON.stringify(sortData);
    // Check if the targetUserId exists in any of the leads

    const userExistsInLeads = selectedLeads && selectedLeads.some((lead) => {
        return lead.users.some((user) => user && user.id && user.id._id === ids);
    });
    //check Lead Assign to the User or not :
    const ExistsInLeads = selectedLeads && selectedLeads.some((lead) => {
        return lead.users.some((user) => user && user.id && user.id._id === currentUser);
    });
    const AssignByAdmin = compairedAdmin ? isEveryItemMatchingAsAdmin : isEveryItemMatching
    const multipleLead = users && users.filter((ele) => {
        const sortedmultipleLeads = ele && ele.role && ele.role.permission && ele.role.permission.map(permissions => permissions.value).sort()
        const permissionsLeadMatch = JSON.stringify(sortedmultipleLeads) === JSON.stringify(sortedUserPermissions);
        return permissionsLeadMatch;
    })
    const allUser = compairedAdmin ? managerUsers : compairedData ? managerUsers && managerUsers.concat(leadUsers) : multipleLead
    const filterAllUser = allUser && allUser.filter((ele) => ele._id != currentUser)
const [assign,setAssign] = useState(false)
    const assignUser = async (values) => {
        try {
            const response = await axios.post(`${config.API_URL}/leadSource/leads/assign/multiple`, {
                leadIds: multiValues,
                userId: ids && ids.ids,
                 userEmail : ids && ids.email,
                 userName : ids && ids.firstName
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json", 
                },
            })
            if (response.data.success) {
                Swal.fire({
                    icon: "success",
                    title: "Woh...",
                    text: "Lead Assigned ",
                });
                setSelectedRowData([])
                dispatch(fetchLeadSource(token));
                dispatch(fetctAllLead(token))
            } if (response.data.message == "There is no lead to assign!") {
                Swal.fire({
                    icon: "error",
                    text: "There is no lead to assign! ",

                });
            }
        } catch (error) {
            setSelectedRowData([])
            Swal.fire({
                icon: "error",
                title: "Oops",
                text: error, // Access the error message from the response data
            });
        }
    }
    // checking ids which user have which Leads Ids :
    const initialValues = {
        selectedUserId: '', // Initial value for the selected user ID
    };

    return (
        <>
            {/* <div className='col-xl-2'></div> */}
            {compairedData || compairedAdmin ? (
                <div className='col-xl-4'>
                    <Formik initialValues={initialValues} onSubmit={assignUser}>
                        {({ isSubmitting, values, setFieldValue }) => (
                            <Form>
                                <label>Lead Assign</label>
                                <div className='d-flex'>
                                    <Field
                                        as="select"
                                        name="selectedUserId"
                                        className="default-select style-1 form-control asn-slt me-1"
                                        onChange={(event) => {
                                            setFieldValue('selectedUserId', event.target.value); // Update the Formik field value
                                            setIds(JSON.parse(event.target.value));
                                            
                                        }}
                                    >
                                        <option value="" data-display="Select">--SELECT USER--</option>
                                        {filterAllUser && filterAllUser.map((ele) => (
                                            <option key={ele._id} value={JSON.stringify({ ids: ele && ele._id, email: ele && ele.email, firstName: ele && ele.firstName})}>
                                                <p>{ele && ele.firstName} {ele && ele.lastName}</p>
                                                <p> ({ele && ele.email})</p>
                                            </option>
                                        ))}
                                    </Field>
                                    <button
                                        type="submit"
                                        className="btn btn-info w-25 fs-14 rounded-pill"
                                        disabled={assign || isSubmitting}
                                    >
                                        {/* <i className="fa-solid fa-share me-1"></i> */}
                                        Assign
                                    </button>
                                </div>
                                <ErrorMessage name="selectedUserId" component="div" className="text-danger" />
                                {userExistsInLeads ? (
                                    <small><strong className='text-danger'>*</strong> This lead is already assigned to <span>user name</span></small>
                                ) : ""}
                            </Form>
                        )}
                    </Formik>
                </div>
            ) : ""}
        </>
    )
}

export default FilterAndAssignLeads
