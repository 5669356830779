import {
    GOOGLE_FAIL,
    GOOGLE_REQUEST,
    GOOGLE_SUCCESS,
  } from "../../constant/GoogleAds/GoogleAds";
  const initialState = {
    loading: false,
  };
  const fetchGoogleAdsReducer = (state = initialState, action) => 
  {
    switch (action.type) {
      case GOOGLE_REQUEST:
        return { ...state, loading: true };
      case GOOGLE_SUCCESS:
        return { ...state, loading: false, userInfo: action.payload };
      case GOOGLE_FAIL:
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };
  export { fetchGoogleAdsReducer };
  