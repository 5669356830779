import React, {Suspense} from 'react';
import './App.css';
import './css/style.css'
import Routes from './components/routes';
import 'bootstrap-select/dist/css/bootstrap-select.min.css';
import 'bootstrap-select';
import 'swiper';
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)
function App() {
  return (
    <>
      <Suspense fallback={loading}>
        <Routes />
      </Suspense>
    </>

  );
}
export default App;
