import React from 'react'
import GenerateForm from './GenerateForm'
import GetGeneratedForm from './GetGeneratedForm'
function index() {
  return (
    <div>
        <GenerateForm />
        <GetGeneratedForm />
    </div>
  )
}

export default index