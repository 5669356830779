import React from 'react'

function TitleBreadCrumb(props) {
    return (
        <>
                <ol className="breadcrumb">
                    <li><h5 className="bc-title">{props && props.title}</h5></li>

                </ol>
        </>
    )
}

export default TitleBreadCrumb