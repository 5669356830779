import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom"
import TitleBreadCrumb from "../../../../components/TitleBreadCrumb"
import { Field, Form, Formik } from 'formik'
import axios from 'axios';
import config from '../../../../config';
import { fetchIframe } from '../../../../redux/action/GoogleAds/GoogleAds';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';

function GenerateForm() {

    const dispatch = useDispatch();
    const [initialValues, setInitialValues] = useState({
        adName: ''
    });
    const token = localStorage.getItem("token")

    const onSubmit = async (values,{ resetForm }) => {
        const id = values.adName.toLowerCase().replace(/[^\w\s]/g, "").replace(/\s+/g, "-");
        const data = { ...values, adId: id }


        try {
            const response = await axios.post(`${config.API_URL}/iframe/add`, data, {
                headers: { Authorization: `Bearer ${token}` }
            });
            dispatch(fetchIframe(token))

            if (response) {
                const closeButton = document.querySelector(".btn-close")
                if (closeButton) {
                    closeButton.click();
                }
                resetForm()
                Swal.fire({
                    icon: "success",
                    text: "Ads Name Added !",
                  });
            }
        }
        catch (error) {
            console.error(error);
        }
    }

    return (
        <>
            <div className='page-titles'>
                <TitleBreadCrumb title="Manual Leads" />
            </div>

            <div className='container-fluid'>
                <div className='row'>
                    <div className="col-xl-12">
                        <div className=" dz-card" id="bootstrap-table2">
                            <div className='row'>
                                <div className="card-header flex-wrap d-flex justify-content-end">
                                    <button type="button" class="btn btn-info btn-sm me-1" data-bs-toggle="modal" data-bs-target="#generate-campaign"><i className="fa fa-plus me-1"></i>Generate IFrame</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="generate-campaign" tabIndex={-1} aria-labelledby="generate-campaignLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-center">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="generate-campaignLabel">Add Google Ads Lead</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            <Formik enableReinitialize="true" initialValues={initialValues} onSubmit={onSubmit}>
                            {({ values, setFieldValue, errors, dirty, isValid, resetForm, isSubmitting }) => {
                              return  <Form>
                                    <div className="row">
                                        <div className="col-xl-12 mb-3">
                                            <label className="form-label"> Advertisement Name</label>
                                            <Field type="text" name="adName" className="form-control" placeholder="Enter Advertisement Name" />
                                        </div>
                                    </div>
                                    <div className=" text-end">
                                        <button type="Submit" className="btn btn-primary" > Add</button>
                                    </div>
                                </Form>
                            }}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GenerateForm