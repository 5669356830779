export const NOTES_SUCCESS = "NOTES_SUCCESS";
export const NOTES_FAIL = "NOTES_FAIL";
export const NOTES_REQUEST = "NOTES_REQUEST";
export const CREATE_NOTE_REQUEST = "CREATE_NOTE_REQUEST";
export const CREATE_NOTE_SUCCESS = "CREATE_NOTE_SUCCESS";
export const CREATE_NOTE_FAIL = "CREATE_NOTE_FAIL";
export const DELETE_FAIL = "DELETE_FAIL";
export const DELETE_REQUEST = "DELETE_REQUEST";
export const DELETE_SUCCESS = "DELETE_SUCCESS";



