import React, { useState } from "react";

import { Outlet, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import TopHeader from "../components/TopHeader";
import SideBar from "../components/SideBar";
import PreLoader from "../components/PreLoader";
import Footer from "../components/Footer";

const DefaultLayout = () => {
  const { loading, userInfo, error } = useSelector((state) => state.userInfo);
  const [toggle, setToggle] = useState(false)
  //show menu-toggle
  return (
    <div id="main-wrapper" className={`show ${toggle ? "menu-toggle" : ""}`}>
      {loading ? (
        <PreLoader />
      ) : ((userInfo?.token) || (userInfo?.verify)) ? (
        <>
          <div className="nav-header">
            <Link to="#" className="brand-logo">
              <img src='/images/fav-icon.png' alt='Solis Technology' className="logo-w p-4" />
              <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                class="brand-title" width="100" height="100" viewBox="0 0 231.000000 103.000000"
                preserveAspectRatio="xMidYMid meet">

                <g transform="translate(0.000000,103.000000) scale(0.100000,-0.100000)"
                  fill="#000000" stroke="none">
                  <path d="M276 951 c-27 -18 -86 -94 -86 -112 0 -4 -25 -21 -56 -39 -31 -17
-54 -34 -51 -37 3 -3 22 5 42 17 20 12 38 20 40 18 2 -2 -2 -28 -10 -58 -25
-93 -35 -237 -24 -341 14 -121 13 -119 27 -152 7 -16 12 -32 12 -37 0 -5 5
-12 11 -16 12 -7 8 20 -7 60 -5 14 4 16 68 16 205 0 379 142 404 329 13 102
-45 207 -136 245 -56 23 -147 28 -217 13 -37 -8 -63 -10 -63 -4 0 18 76 86
105 93 23 6 32 2 47 -16 22 -27 24 -13 2 18 -19 28 -68 29 -108 3z m219 -128
c108 -56 144 -176 91 -305 -60 -146 -188 -230 -355 -232 l-65 -1 -9 40 c-18
73 -14 283 6 360 31 118 44 138 102 152 72 18 181 11 230 -14z"/>
                  <path d="M1534 873 c-14 -31 -16 -65 -12 -183 l5 -145 -45 -40 c-24 -21 -51
-54 -58 -72 -19 -43 -18 -137 0 -152 12 -10 13 -6 9 27 -7 53 20 131 58 170
17 18 35 32 39 32 5 0 15 -28 22 -62 12 -52 12 -68 2 -89 -22 -45 -65 -89 -87
-90 -16 -1 -18 -2 -5 -6 26 -7 53 12 86 61 l29 45 24 -25 c13 -14 33 -30 44
-36 15 -7 13 -3 -6 14 -53 48 -53 50 -28 143 20 69 28 85 44 86 17 1 17 2 3 6
-16 4 -17 14 -12 91 10 145 -21 252 -75 260 -17 3 -25 -5 -37 -35z m59 -1 c9
-10 21 -42 27 -72 12 -61 13 -210 2 -226 -4 -6 -23 -14 -43 -18 l-36 -6 -6 67
c-11 111 -8 201 7 238 17 40 26 43 49 17z m27 -328 c0 -17 -32 -114 -39 -119
-5 -2 -11 8 -14 24 -3 16 -9 40 -13 55 -5 21 -2 28 17 35 28 11 49 13 49 5z"/>
                  <path d="M1810 542 c-34 -35 -54 -120 -44 -188 5 -34 3 -49 -7 -56 -11 -8 -11
-9 3 -5 11 4 24 -6 41 -32 29 -43 103 -85 139 -78 21 4 21 4 -3 6 -34 1 -88
38 -115 78 l-21 32 42 44 c41 43 75 117 75 162 0 24 -26 65 -41 65 -6 0 -3 -8
6 -17 31 -31 7 -122 -53 -200 -13 -18 -28 -30 -33 -27 -12 7 -11 121 1 166 6
20 22 46 37 58 14 11 20 20 14 20 -7 0 -25 -13 -41 -28z"/>
                  <path d="M1215 542 c-80 -38 -141 -133 -116 -181 6 -11 11 -28 11 -39 0 -11 7
-23 16 -26 23 -9 87 -7 102 3 7 4 -12 6 -43 3 -56 -5 -57 -4 -57 38 0 11 0 27
1 35 1 22 82 107 96 101 18 -7 62 31 49 43 -15 16 -93 -37 -128 -87 -34 -50
-45 -49 -21 2 37 79 141 135 194 105 16 -9 29 -28 35 -51 12 -47 -20 -115 -72
-152 -40 -29 -37 -36 5 -9 14 10 39 35 54 57 34 47 39 111 11 146 -24 30 -86
36 -137 12z"/>
                  <path d="M794 518 c-42 -47 -96 -170 -92 -210 2 -28 8 -34 31 -36 32 -4 80 23
122 69 28 31 26 30 -13 -4 -74 -65 -122 -72 -122 -18 0 34 48 151 62 151 5 0
7 4 4 8 -2 4 4 19 14 32 14 19 22 22 30 14 9 -9 9 -17 1 -33 -7 -12 -8 -21 -3
-21 17 0 32 25 32 52 0 39 -29 37 -66 -4z"/>
                  <path d="M975 526 c-36 -36 -66 -103 -72 -161 -5 -44 -2 -56 16 -74 22 -22 56
-27 81 -11 11 7 5 8 -20 4 -50 -7 -65 7 -64 58 1 43 34 122 71 167 29 35 47
19 40 -35 -2 -24 -1 -44 3 -44 24 0 28 105 4 114 -25 10 -34 7 -59 -18z"/>
                  <path d="M26 422 c-16 -26 4 -80 42 -112 20 -16 43 -30 52 -30 8 0 0 8 -18 17
-39 19 -54 43 -62 98 -3 27 -8 36 -14 27z"/>
                  <path d="M2095 371 c112 -17 61 -147 -71 -181 l-39 -10 38 5 c79 9 157 72 157
127 0 34 -45 68 -87 67 l-38 -2 40 -6z"/>
                  <path d="M1992 344 c-12 -8 -22 -25 -21 -37 0 -15 2 -17 6 -7 3 8 15 25 26 38
24 26 20 28 -11 6z"/>
                  <path d="M1025 300 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z" />
                  <path d="M1287 190 c-3 -11 -2 -18 3 -15 4 3 18 -22 30 -55 12 -33 26 -60 31
-60 5 0 -1 24 -12 53 -12 28 -25 62 -30 75 -11 27 -15 28 -22 2z"/>
                  <path d="M1410 135 c0 -43 4 -75 10 -75 6 0 10 32 10 75 0 43 -4 75 -10 75 -6
0 -10 -32 -10 -75z"/>
                  <path d="M1559 133 c4 -64 6 -71 10 -38 7 46 2 115 -8 115 -3 0 -4 -35 -2 -77z" />
                  <path d="M1103 183 c-4 -11 4 -25 27 -45 19 -15 38 -28 42 -28 4 0 8 -12 9
-27 l0 -28 12 24 c12 25 14 21 -62 84 -12 10 -19 22 -16 27 3 6 4 10 0 10 -3
0 -9 -8 -12 -17z"/>
                  <path d="M1694 185 c-8 -19 11 -42 48 -61 20 -10 28 -22 29 -42 l0 -27 12 24
c12 25 14 21 -62 84 -12 10 -19 22 -16 27 3 6 4 10 0 10 -3 0 -8 -7 -11 -15z"/>
                  <path d="M1625 140 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
                  <path d="M1100 75 c0 -8 4 -15 10 -15 5 0 7 7 4 15 -4 8 -8 15 -10 15 -2 0 -4
-7 -4 -15z"/>
                  <path d="M1690 75 c0 -8 4 -15 10 -15 5 0 7 7 4 15 -4 8 -8 15 -10 15 -2 0 -4
-7 -4 -15z"/>
                </g>
              </svg>
            </Link>
            <div className="nav-control">
              <div className={`hamburger ${toggle ? "is-active" : ""}`} onClick={() => setToggle(!toggle)}>
                <span className="line" />
                <span className="line" />
                <span className="line" />
              </div>
            </div>
          </div>
          <TopHeader />
          <SideBar />
          <div className="content-body">
            <Outlet />
          </div>
          <Footer />
        </>
      ) : (
        window.location.replace("/#/user/login")
      )}
    </div>
  );
};

export default DefaultLayout;
