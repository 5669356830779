import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import * as Yup from "yup";
import Select from 'react-select';
import config from '../../../config';
import axios from 'axios';
import Swal from 'sweetalert2';
function ManageAccount() {
    var token = localStorage.getItem("token");
    const [finalData, setFinalData] = useState("");
    const initialValues = {
        fbPages: ""
        // permission: ""
    };
    const validationSchema = Yup.object({
        // fbPages: Yup.string().required("Pages  are required"),
    });
    //load facebook API 
    const [loader,setLoader] = useState(false)
    useEffect(() => {
        const fetchPagesfromFacebook = async () => {
            setLoader(true)
            try {
                const response = await axios.get(
                    `${config.API_URL}/leadSource/load/fb/page`,

                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                            // Add any other headers you need
                        },
                    }
                );
                setFinalData(response.data.data);
                setLoader(false)
            } catch (error) {
                setLoader(false)
                console.error("Error fetching ads:", error.message);
            }
        };
        fetchPagesfromFacebook(); // Call the async function here
    }, []);
    const onSubmit = async (values, { resetForm }) => {
        try {

          const response = await axios.post(`${config.API_URL}/leadSource/save/pages`, {pagesDataArray:values.fbPages }, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          });
          const userData = response.data;

          if (userData.code == "DUPLICATION") {
            Swal.fire({
              icon: 'warning',
              text: 'Role Already Exists',

            })
          }
          if (userData.code == "SUCCESS") {
            Swal.fire({
              icon: 'success',
              text: 'Role Added Succesfully',
            })
          }
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Oops',
            text: error,
          })

        } 
    };
    const options = finalData && finalData.map(item => ({
        value: item.id,
        label: item.name,
        access_token : item.access_token
        // You can add more properties if needed
    }));
    return (
        <>
            <div className="modal fade" id="manageaccount" tabIndex={-1} aria-labelledby="manageaccount" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel1"><i class="fa-regular fa-comment me-2"></i>Select Your organization Facebook Pages </h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            <Formik
                                enableReinitialize={true}
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={onSubmit}
                            >
                                {({ values, setFieldValue, errors, dirty, isValid }) => {
                                    
                                    return (
                                        <Form>
                                            <div className="row">
                                                <div className="col-xl-12 mb-3">

                                                    <div className="col-xl-6 mb-3">
                                                        <label className="form-label">Facebook Pages<span className="text-danger">*</span></label>
                                                        <Select
                                                            isMulti
                                                            name="fbPages"
                                                            options={options}
                                                            className="basic-multi-select py-2.5"
                                                            classNamePrefix="select"
                                                            value={values.fbPages} // Use values.fbPages for the initial value
                                                            onChange={(selectedOptions) => setFieldValue("fbPages", selectedOptions)}
                                                            required
                                                        />
                                                        <ErrorMessage
                                                            name="fbPages"
                                                            render={(msg) => (
                                                                <small style={{ color: "red" }}>{msg}</small>
                                                            )}
                                                        />
                                                    </div></div>
                                                <div className='col-xl-12'>
                                                    <button type="submit" className="btn btn-primary  float-right"><i class="fa-regular fa-paper-plane me-2"></i>Submit</button>
                                                </div>
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ManageAccount