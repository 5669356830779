import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <>
    <div className="footer">
            <div className="copyright">
              <p> © Copyright 2023- Developed by <Link to="#" target="_blank">Solis Technology</Link> | All Rights Reserved</p>
            </div>
          </div>
    </>
  )
}

export default Footer