import axios from 'axios';
import { GOOGLE_FAIL, GOOGLE_SUCCESS, GOOGLE_REQUEST } from '../../constant/GoogleAds/GoogleAds';
import { IFRAME_SUCCESS, IFRAME_REQUEST, IFRAME_FAIL } from '../../constant/GoogleAds/Iframe'
import config from '../../../config';
const fetchGoogleAds = (token) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GOOGLE_REQUEST });
            const data = await axios.get(`${config.API_URL}/googleAds/get`, { headers: { Authorization: `Bearer ${token}` } })

            const GoogleAdsData = data.data;
            dispatch({ type: GOOGLE_SUCCESS, payload: GoogleAdsData })
        } catch (err) {
            dispatch({ type: GOOGLE_FAIL, payload: err });
        }
    }
};
const fetchIframe = (token) => {
    return async (dispatch) => {
        try {
            dispatch({ type: IFRAME_REQUEST });
            const data = await axios.get(`${config.API_URL}/iframe/get`, { headers: { Authorization: `Bearer ${token}` } })

            const iframe = data.data;
            dispatch({ type: IFRAME_SUCCESS, payload: iframe })
        } catch (err) {
            dispatch({ type: IFRAME_FAIL, payload: err });
        }
    }
};
export { fetchGoogleAds, fetchIframe }