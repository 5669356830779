
import axios from "axios";
import {
    PROFILE_SUCCESS,
    PROFILE_FAIL,
    PROFILE_REQUEST,
} from "../../constant/profille/profile";
import config from '../../../config'

const getProfile = (token , data ) => {
    return async (dispatch) => {
        try {
            dispatch({ type: PROFILE_REQUEST });
            const data = await axios.get(`${config.API_URL}/auth/info`, { headers: { Authorization: `Bearer ${token}` } });
            const profile = data.data;
            dispatch({ type: PROFILE_SUCCESS, payload: profile });
        } catch (err) {
            dispatch({ type: PROFILE_FAIL, payload: err });

        }
    }
};

export default getProfile ;