
import {
    IFRAME_FAIL,
    IFRAME_REQUEST,
    IFRAME_SUCCESS,
  } from "../../constant/GoogleAds/Iframe";
  const initialState = {
    loading: false,
  };
  const fetchIframeReducer = (state = initialState, action) => 
  {
    switch (action.type) {
      case IFRAME_REQUEST:
        return { ...state, loading: true };
      case IFRAME_SUCCESS:
        return { ...state, loading: false, userInfo: action.payload };
      case IFRAME_FAIL:
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };
  export { fetchIframeReducer };
  