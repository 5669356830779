import {
    PIPELINE_SUCCESS,
    PIPELINE_FAIL,
    PIPELINE_REQUEST
   
  } from "../../constant/Dashboard/pipeline";
  const initialState = {
    loading: false,
   
  };
  const pipelineReducer = (state = initialState, action) => {
   
    switch (action.type) {
      case PIPELINE_REQUEST:
        return { ...state, loading: true };
      case PIPELINE_SUCCESS:
        return { ...state, loading: false, userInfo: action.payload };
      case PIPELINE_FAIL:
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };
  export { pipelineReducer };
  