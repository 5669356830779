
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { useDispatch, useSelector } from "react-redux";
import config from "../../../../config";
import axios from "axios";
import IframeModal from "./IframeModal";
import { fetchIframe } from "../../../../redux/action/GoogleAds/GoogleAds";
// import TitleBreadCrumb from '../../../components/TitleBreadCrumb';
function GetGeneratedForm() {
    const [data, setData] = useState([]);
    const [adData, SetAdData] = useState({
        adName: "",
        adId: "",
        company: ""
    })

    const AdDataControl = (row) => {
        if(row) SetAdData({
            adName: row?.adName,
            adId: row?.adId,
            company: row?.company
        })
    }
    var token = localStorage.getItem("token");
    const { loading, userApi, error } =
        useSelector((store) => store) || " ";
    const userData = userApi?.userInfo?.data
    const IframeData = useSelector((store)=>store?.fetchIframeReducer?.userInfo?.data)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchIframe(token))
    }, [])

    const [columns, setColumns] = useState([

        {
            name: "Ads Name",
            selector: (row) => row?.adName,
        },
        {
            name: "Ads ID",
            selector: (row) => row?.adId,
        },
        {
            name: "Action",
            cell: row =>
                <div >
                    <button className="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#iframeModal" onClick={() => AdDataControl(row)}>
                        <i className="fa fa-code me-1"></i>
                        Iframe
                    </button>
                </div>
        },
    ])
    return (
        <>
            <div className='page-titles'>
            </div>
            <div className='container-fluid'>
                <div className='row'>
                    <div className="col-xl-12 bst-seller">
                        <div className="card">
                            <div className="card-body p-0">
                                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting ">
                                    <div className="tbl-caption">
                                        <h4 className="heading mb-0">
                                        </h4>
                                    </div>
                                    <DataTableExtensions columns={columns.filter((coldef) => coldef.hide != true)} data={IframeData}>
                                        <DataTable
                                            // customStyles={customStyles}
                                            columns={columns}
                                            data={IframeData}
                                            direction="auto"
                                            fixedHeader
                                            fixedHeaderScrollHeight="1000px"
                                            pagination
                                            responsive
                                            striped
                                            subHeaderAlign="right"
                                            subHeaderWrap
                                            selectableRows
                                        // onSelectedRowsChange={handleSelectedRowsChange}
                                        />
                                    </DataTableExtensions>
                                </div>
                                <IframeModal adData={adData}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default GetGeneratedForm;
