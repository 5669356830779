import axios from "axios";
import {
 PIPELINE_SUCCESS,
  PIPELINE_FAIL,
  PIPELINE_REQUEST
  } from "../../constant/Dashboard/pipeline";
  import config from '../../../config'
const getPipeline = (token ) => {

    return async (dispatch) => {
      try {
        dispatch({ type:  PIPELINE_REQUEST });
        const data = await axios.get(`${config.API_URL}/dashboard/pipeline/leads`, { headers:{ Authorization: `Bearer ${token}` }},);
        const pipeline = data.data;
        
        dispatch({ type: PIPELINE_SUCCESS, payload: pipeline });
      } catch (err) {
          dispatch({ type: PIPELINE_FAIL, payload:err});
       
        }
      }
    };
  
  export {getPipeline}